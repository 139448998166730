import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function Nemesis(){

	const navigate = useNavigate();

	return(
		<div className='App'>
			<header className='App-header'>
				<button className='header-button' onClick={() => {navigate('/')}}>Back</button>
				<h1>Τι είναι το ΝΕΜΕΣΙΣ</h1>
			</header>
			<main className='App-main'>
				<div className='Main-text'>
				<ul>
					<li><span style={{fontSize: "35px"}}>Δεν είναι κόμμα</span></li>
					<li>Δεν ανήκουμε σε κανένα κόμμα, όμως είμαστε ανοιχτοί σε συνεργασίες με κόμματα, 
						φορείς και οργανισμούς που προωθούν δράσεις οι οποίες ευθυγραμμίζονται με τις αξίες και τις αρχές του NEMESIS.</li>
					<li>Δεν πέρνει εισφορές από τα μέλη του ούτε χρηματοδοτείτε από κανένα</li>
					<li>Αναζητεί τον Άνθρωπο όπου και να είναι</li>
					<li>Αποτελείτε από μέλη που ανήκουν σε διαφορετικές κομματικές παρατάξεις</li>
					<li>Σέβεται και αποδέχεται ανθρώπους από όλα τα στρώματα κοινωνικής τάξης χωρίς να υπάρχει διάκριση μεταξύ τους</li>
					<li>Κοινωνική συνάθρηση σκεπτόμενων υγιεώς πολιτών με γνώμονα την εφαρμογή της δημοκρατίας 
					στα σωστά πλαίσια που καθορίζει το σύνταγμα.</li>
					<li>Αποζητά την δικαιοσύνη, την αξιοπρέπεια, την αξιοκρατία και την ασφάλεια στους νόμιμους πολίτες της κυπριακής δημοκρατίας πάντα με γνώμονα τον νόμο</li>
				</ul>
				</div>
			</main>
			<footer className="App-footer">
            <p>&copy; 2024 Erex8ewn NEMESIS</p>
          </footer>
		</div>
	)
}