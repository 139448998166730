// App.js
import React, { useEffect, useRef, useState } from 'react';
import './index.css';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import Akribeia from "./components/Akribeia";
import Fysikoi_poroi from "./components/Fysikoi_poroi";
import Katoxiko from "./components/Katoxiko";
import Kratikoi_Foreis from "./components/Kratikoi_Foreis";
import Metanasteftiko from "./components/Metanasteftiko";
import Oikogenia from "./components/Oikogenia";
import Psifiakos from "./components/Psifiakos";
import Nemesis from './components/Nemesis';
import Kyprios from "./components/Kyprios";
import akrivia from "./images/akrivia.jpg";
import fysikoi_poroi from "./images/fysikoi_poroi.jpg";
import katoxiko from "./images/katoxiko.jpg"; 
import kratikoi_foreis from "./images/kratikoi_foreis.jpg";
import metanasteftiko from "./images/metanasteftiko.jpg";
import oikogenia from "./images/oikogenia.jpg";
import psifiakos from "./images/psifiakos.jpg";
import logo from "./images/logo.jpg";
import flag from "./images/cyprus_flag.jpg";

const App = () => {
  const navigate = useNavigate();

  // const trackRef = useRef(null);
  // const [mouseDownAt, setMouseDownAt] = useState(0);
  // const [percentage, setPercentage] = useState(0);
  // const [prevPercentage, setPrevPercentage] = useState(0);

  // const handleOnDown = (e) => {
  //   setMouseDownAt(e.clientX);
  // };

  // const handleOnUp = () => {
  //   setMouseDownAt(0);
  //   setPrevPercentage(percentage);
  // };

  // const handleOnMove = (e) => {
  //   if (mouseDownAt === 0) return;
  
  //   const mouseDelta = mouseDownAt - e.clientX;
  //   const maxDelta = window.innerWidth / 2;
  
  //   const nextPercentageUnconstrained = prevPercentage + (mouseDelta / maxDelta) * -40;
  //   const nextPercentage = Math.max(Math.min(nextPercentageUnconstrained, 0), -100);
  
  //   setPercentage(nextPercentage);
  
  //   const track = trackRef.current;
  //   if (track) { // Added this line
  //     track.animate({
  //       transform: `translate(${nextPercentage}%, 0)`
  //     }, { duration: 1400, fill: 'forwards' });
  
  //     for (const image of track.getElementsByClassName('image')) {
  //       image.animate({
  //         objectPosition: `${100 + nextPercentage}% center`
  //       }, { duration: 1400, fill: 'forwards' });
  //     }
  //   }
  // };  

  // useEffect(() => {
  //   const handleMouseDown = (e) => handleOnDown(e);
  //   const handleMouseUp = (e) => handleOnUp(e);
  //   const handleMouseMove = (e) => handleOnMove(e);

  //   window.addEventListener('mousedown', handleMouseDown);
  //   window.addEventListener('mouseup', handleMouseUp);
  //   window.addEventListener('mousemove', handleMouseMove);
  //   window.addEventListener('touchstart', (e) => handleOnDown(e.touches[0]));
  //   window.addEventListener('touchend', (e) => handleOnUp(e.touches[0]));
  //   window.addEventListener('touchmove', (e) => handleOnMove(e.touches[0]));

  //   return () => {
  //     window.removeEventListener('mousedown', handleMouseDown);
  //     window.removeEventListener('mouseup', handleMouseUp);
  //     window.removeEventListener('mousemove', handleMouseMove);
  //     window.removeEventListener('touchstart', (e) => handleOnDown(e.touches[0]));
  //     window.removeEventListener('touchend', (e) => handleOnUp(e.touches[0]));
  //     window.removeEventListener('touchmove', (e) => handleOnMove(e.touches[0]));
  //   };
  // }, [mouseDownAt, percentage, prevPercentage]);

  // const handleOnWheel = (e) => {
  //   const deltaY = e.deltaY;
  //   const maxDelta = window.innerWidth / 2;

  //   const nextPercentageUnconstrained = percentage + (deltaY / maxDelta) * -25;
  //   const nextPercentage = Math.max(Math.min(nextPercentageUnconstrained, 0), -100);

  //   setPercentage(nextPercentage);

  //   const track = trackRef.current;
  //   if (track) {
  //     track.animate({
  //       transform: `translate(${nextPercentage}%, 0)`
  //     }, { duration: 1400, fill: 'forwards' });

  //     for (const image of track.getElementsByClassName('image')) {
  //       image.animate({
  //         objectPosition: `${100 + nextPercentage}% center`
  //       }, { duration: 1400, fill: 'forwards' });
  //     }
  //   }
  // };

  // useEffect(() => {
  //   const handleMouseDown = (e) => handleOnDown(e);
  //   const handleMouseUp = (e) => handleOnUp(e);
  //   const handleMouseMove = (e) => handleOnMove(e);
  //   const handleWheel = (e) => handleOnWheel(e);

  //   window.addEventListener('mousedown', handleMouseDown);
  //   window.addEventListener('mouseup', handleMouseUp);
  //   window.addEventListener('mousemove', handleMouseMove);
  //   window.addEventListener('wheel', handleWheel);

  //   window.addEventListener('touchstart', (e) => handleOnDown(e.touches[0]));
  //   window.addEventListener('touchend', (e) => handleOnUp(e.touches[0]));
  //   window.addEventListener('touchmove', (e) => handleOnMove(e.touches[0]));

  //   return () => {
  //     window.removeEventListener('mousedown', handleMouseDown);
  //     window.removeEventListener('mouseup', handleMouseUp);
  //     window.removeEventListener('mousemove', handleMouseMove);
  //     window.removeEventListener('wheel', handleWheel);

  //     window.removeEventListener('touchstart', (e) => handleOnDown(e.touches[0]));
  //     window.removeEventListener('touchend', (e) => handleOnUp(e.touches[0]));
  //     window.removeEventListener('touchmove', (e) => handleOnMove(e.touches[0]));
  //   };
  // }, [mouseDownAt, percentage, prevPercentage]);

  return (
    <Routes>
      <Route path='/' element={
        <div className="App">
          <header className="App-header">
            <h1 id='nemesis2'>ΕΡΕΧΘΕΩΝ ΝΕΜΕΣΙΣ</h1>
            <p className="App-header-p">Είμαστε μια οικογένεια ενεργών πολιτών. Το Νέμεσις είσαι εσύ!</p>
          </header>
          <main className="App-main">
            {/* ref={trackRef} */}
            <div className="content-div" >
              <div className='image-container'>
                <span className='image-text'>Τι είναι το NEMESIS</span>
                <img className='image' src={logo} onClick={() => {navigate('/nemesis')}} />
              </div>
              <div className='image-container'>
                <span className='image-text'>Ακρίβεια</span>
                <img className='image' src={akrivia} onClick={() => {navigate('/akriveia')}} />
              </div>
              <div className='image-container'>
                <span className='image-text'>Πρωτογενής τομέας</span>
                <img className='image' src={fysikoi_poroi} onClick={() => {navigate('/fysikoi_poroi')}} />
              </div>
              <div className='image-container'>
                <span className='image-text'>Κατοχικό</span>
                <img className='image' src={katoxiko} onClick={() => {navigate('/katoxiko')}} />
              </div>
              <div className='image-container'>
                <span className='image-text'>Κρατικοί Φορείς</span>
                <img className='image' src={kratikoi_foreis} onClick={() => {navigate('/kratikoi_foreis')}} />
              </div>
              <div className='image-container'>
                <span className='image-text'>Μεταναστευτικό</span>
                <img className='image' src={metanasteftiko} onClick={() => {navigate('/metanasteftiko')}} />
              </div>
              <div className='image-container'>
                <span className='image-text'>Κυπριακή Οικογένεια</span>
                <img className='image' src={oikogenia} onClick={() => {navigate('/oikogenia')}} />
              </div>
              <div className='image-container'>
                <span className='image-text'>Ψηφιακός Πολίτης</span>
                <img className='image' src={psifiakos} onClick={() => {navigate('/psifiakos')}} />
              </div>
              <div className='image-container'>
                <span className='image-text'>Κύπριος Πολίτης</span>
                <img className='image' src={flag} onClick={() => {navigate('/kyprios_politis')}} />
              </div>
            </div>
          </main>
          <footer className="App-footer">
          <div className="footer-left">
              <p>&copy; 2024 Erex8ewn NEMESIS</p>
          </div>
          <div className="footer-right">
              <a href="https://www.instagram.com/erex8ewnhghmwn" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                  <i className="fab fa-instagram"></i>
              </a>
              <a href="https://www.tiktok.com/@aixmhros" target="_blank" rel="noopener noreferrer" aria-label="TikTok" className="tiktok">
                  <i className="fab fa-tiktok"></i>
              </a>
              <a href="https://www.youtube.com/@EREX8EWNHGHMWN" target="_blank" rel="noopener noreferrer" aria-label="YouTube" className="youtube">
            <i className="fab fa-youtube"></i>
              </a>
          </div>
          </footer>
        </div>
      }/>
      <Route path='/nemesis' element={<Nemesis />} />
      <Route path='/akriveia' element={<Akribeia />}/>
      <Route path='/fysikoi_poroi' element={<Fysikoi_poroi />}/>
      <Route path='/katoxiko' element={<Katoxiko />}/>
      <Route path='/kratikoi_foreis' element={<Kratikoi_Foreis />}/>
      <Route path='/metanasteftiko' element={<Metanasteftiko />}/>
      <Route path='/oikogenia' element={<Oikogenia />}/>
      <Route path='/psifiakos' element={<Psifiakos />}/>
      <Route path='/kyprios_politis' element={<Kyprios />}/>
    </Routes>
  );
}

export default App;
