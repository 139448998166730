import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function Psifiakos(){

	const navigate = useNavigate();

	return(
		<div className='App'>
			<header className='App-header'>
				<button className='header-button' onClick={() => {navigate('/')}}>Back</button>
				<h1>Ψηφιακός Πολίτης</h1>
			</header>
			<main className='App-main' id='App-main-component'>
				
			<div className='Main-text'>
					<ul>
						<li>Μέτρα κατα των κινδύνων που αφορούν την υποκλοπή προσωπικών δεδομένων στο ηλεκτρικό σύστημα.</li>
						<li>Λειτουργία του συστήματος μόνο εφόσον υπάρχει πλήρης ασφάλεια και εγγυήσεις.</li>
						<li>Απόκτηση τεχνογνωσίας του συστήματος από εμπειρογνώμονες άλλων χωρών με πείρα λειτουργίας του εν λόγω συστήματος.</li>
					</ul>
				</div>
				<div className='Main-image' id='psifiakos'>

				</div>
			</main>
			<footer className="App-footer">
            <p>&copy; 2024 Erex8ewn NEMESIS</p>
          </footer>
		</div>
	)
}