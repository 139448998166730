import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function Katoxiko(){

	const navigate = useNavigate();

	return(
		<div className='App'>
			<header className='App-header'>
				<button className='header-button' onClick={() => {navigate('/')}}>Back</button>
				<h1>Κατοχικό</h1>
			</header>
			<main className='App-main' id='App-main-component'>
				<div className='Main-text'>
					<ul>
						<li>Επανέλεγχος των κριτηρίων για τα επιδόματα στέγασης των προσφύγων για δικαιότερη κατανομή των πόρων.</li>
						<li>Υποστηρίζουμε μία δίκαιη λύση του κυπριακού που βασίζετε στην αποχώρηση των τουρκικών στρατευμάτων χωρίς εγγυήσεις της Τουρκίας και ένα κράτος.</li>
						<li>Αυστηρός έλεγχος των κονδυλίων που διατίθενται στα κατεχόμενα για σωστή και δίκαιη χρήση.</li>
						<li>Παρακολούθηση μεταναστευτικής πολιτικής του κράτους.</li>
					</ul>
				</div>
				<div className='Main-image' id='katoxiko'>
					
				</div>
			</main>
			<footer className="App-footer">
            <p>&copy; 2024 Erex8ewn NEMESIS</p>
          </footer>
		</div>
	)
}