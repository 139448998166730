import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function Fysikoi_poroi(){

	const navigate = useNavigate();

	return(
		<div className='App'>
			<header className='App-header'>
				<button className='header-button' onClick={() => {navigate('/')}}>Back</button>
				<h1>Πρωτογενής τομέας</h1>
			</header>
			<main className='App-main' id='App-main-component'>
				
				<div className='Main-text'>
					<ul>
						<li>Προώθηση οικονομικής ανάπτυξης και βιωσιμότητας στον τομέα της γεωργίας και της κτηνοτροφίας.</li>
						<li>Λήψη μέτρων για την ενίσχυση των τομέων γεωργίας και κτηνοτροφίας.</li>
						<li>Διασφάλιση του ελέγχου των μισθών των υπαλλήλων σε αυτούς τους τομείς.</li>
						<li>Εξασφάλιση της νομιμότητας της εργασίας στους τομείς της γεωργίας και κτηνοτροφίας με 
							σκοπό την μεγιστοποίηση της αυτονομίας τις κυπριακής δημοκρατίας ως αναφορά τον γεωργοκτηνοτροφικό τομέα.</li>
						<li>Εξασφάλιση δίκαιων και ασφαλών συνθηκών εργασίας.</li>
						<li>Προστασία του φυσικού περιβάλλοντος και αποφυγή μελλοντικών καταστροφών.</li>
						<li>Παραχώρηση κρατικής γης σε νεαρά ζευγάρια και χαμηλότοκα δάνεια για απασχόληση τους στον γεωργοκτηνοτροφικό τομέα.</li>
					</ul>
				</div>

				<div className='Main-image' id='fysikoi_poroi'>
						
				</div>
			</main>
			<footer className="App-footer">
            <p>&copy; 2024 Erex8ewn NEMESIS</p>
          </footer>
		</div>
	)
}