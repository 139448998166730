import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function Kratikoi_Foreis(){

	const navigate = useNavigate();

	return(
		<div className='App'>
			<header className='App-header'>
				<button className='header-button' onClick={() => {navigate('/')}}>Back</button>
				<h1>Κρατικοί Φορείς</h1>
			</header>
			<main className='App-main' id='App-main-component'>

				
			<div className='Main-text'>
					<ul>
						<li>Βελτίωση της επικοινωνίας μεταξύ των διαφόρων φορέων για μείωση της γραφειοκρατίας.</li>
						<li>Επιλογή στους πολίτες να επιλέγουν διαδυκτιακά η προσωπικά για τις διάφορες παροχές ή επιδόματα.</li>
						<li>Εξασφάλιση ότι και οι δύο επιλογές λειτουργούν χωρίς δυσλειτουργίες.</li>
						<li>Προσφορά ευελιξίας και άνεσης στους πολίτες και αμεσότητα στην παροχή επιδομάτων που δικαιούνται.</li>
					</ul>
				</div>

				<div className='Main-image' id='kratikoi_foreis'>
					
				</div>
			</main>
			<footer className="App-footer">
            <p>&copy; 2024 Erex8ewn NEMESIS</p>
          </footer>
		</div>
	)
}