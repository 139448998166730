import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function Psifiakos(){

	const navigate = useNavigate();

	return(
		<div className='App'>
			<header className='App-header'>
				<button className='header-button' onClick={() => {navigate('/')}}>Back</button>
				<h1>Κύπριος Πολίτης</h1>
			</header>
			<main className='App-main' id='App-main-component'>
				
			<div className='Main-text'>
					<ul>
						<li>Πλήρης ευαρμογή του συντάγματος που λέει ρητά ότι το κράτος είναι υπόχρεο να παρέχει 
							στέγη, τροφή, ασφάλεια, εργασία, ισονομία, ισοπολιτεία σε όλους τους πολίτες του.</li>
						<li>Διεξαγωγή δημοψηφισμάτων για σοβαρά θέματα που επηρεάζουν την ποιότητα ζωής ή την ασφάλεια ή την ελευθερία των πολιτών.</li>
						<li>Eφαρμογή οριζόντιας ψηφοφορίας.</li>
					</ul>
				</div>
				<div className='Main-image' id='kyprios'>

				</div>
			</main>
			<footer className="App-footer">
            <p>&copy; 2024 Erex8ewn NEMESIS</p>
          </footer>
		</div>
	)
}