import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function Metanasteftiko(){

	const navigate = useNavigate();

	return(
		<div className='App'>
			<header className='App-header'>
				<button className='header-button' onClick={() => {navigate('/')}}>Back</button>
				<h1>Μεταναστευτικό</h1>
			</header>
			<main className='App-main' id='App-main-component'>
				
			<div className='Main-text'>
					<ul>
						<li>Μείωση των παράνομων μεταναστών στο 1% του κυπριακού πληθυσμού, σύμφωνα με τον κανονισμό της Ευρωπαϊκής Ένωσης.</li>
						<li>Εξασφάλιση κοινωνικής και οικονομικής σταθερότητας της Κύπρου.</li>
						<li>Αυστηρός έλεγχος για να διασφαλιστεί ότι η αστυνομία της Κύπρου δεν διακρίνει στις εγκληματικές ενέργειες μεταξύ παράνομων μεταναστών και Κυπρίων πολιτών.</li>
						<li>Έλεγχος για την οικονομική διάκριση στα επιδόματα μεταξύ παράνομων μεταναστών και Κυπρίων πολιτών.</li>
						<li>Άμεση διόρθωση των αδικιών και εξασφάλιση δίκαιης κατανομής των πόρων.</li>
					</ul>
				</div>
				<div className='Main-image' id='metanasteftiko'>

				</div>
			</main>
			<footer className="App-footer">
            <p>&copy; 2024 Erex8ewn NEMESIS</p>
          </footer>
		</div>
	)
}