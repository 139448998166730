import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function Oikogenia(){

	const navigate = useNavigate();

	return(
		<div className='App'>
			<header className='App-header'>
				<button className='header-button' onClick={() => {navigate('/')}}>Back</button>
				<h1>Κυπριακή Οικογένεια</h1>
			</header>
			<main className='App-main' id='App-main-component'>
				
			<div className='Main-text'>
					<ul>
						<li>Κίνητρα και διευκολύνσεις προς νεαρά ζευγάρια για αντιμετώπιση της υπογεννητικότητας.</li>
						<li>Αύξηση της άδειας πατρότητας και ταυτόχρονη ύπαρξή της με την άδεια μητρότητας.</li>
						<li>Αύξηση του επιδόματος τέκνου με βάση τα κριτήρια της ευρωπαϊκής ένωσης σύμφωνα με το βιωτικό επίπεδο της χώρας.</li>
						<li>Άμεση λήψη συγκεκριμένων μέτρων για την αντιμετώπιση της υπογεννητικότητας.</li>
						<li>Βελτιστοποίηση των ωραρίων των σχολίων ως προς το συμφέρον των γονέων και των παιδιών.</li>
						<li>Αναγκαιότητα παροχής βοηθήματος σε νέες οικογένειες.</li>
						<li>Δημιουργία ενός πιο ευέλικτου και υποστηρικτικού περιβάλλοντος για την ανατροφή των παιδιών.</li>
						<li>παραχώρηση οικονομικής βοήθειας (πχ.€40000) υπό μορφή άτοκου δανείου το οποίο κλιμακωτά με την γέννα τριών παιδιών θα διαγράφεται.</li>
						<li>Λειτουργία του ολοήμερου σχολείου που θα καταστήσει πραγματικά την παιδεία σε δωρεάν και όχι όπως τωρα που η παραπαιδεία οργιάζει στοιχίζοντας στον πολίτη ενα υπέρογκο ποσό σε φροντιστήρια για την μόρφωση των παιδιών του.</li>
					</ul>
				</div>
				<div className='Main-image' id='oikogenia'>

				</div>
			</main>
			<footer className="App-footer">
            <p>&copy; 2024 Erex8ewn NEMESIS</p>
          </footer>
		</div>
	)
}