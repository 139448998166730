import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function Akribeia(){

	const navigate = useNavigate();

	return(
		<div className='App'>
			<header className='App-header'>
				<button className='header-button' onClick={() => {navigate('/')}}>Back</button>
				<h1>Ακρίβεια</h1>
			</header>
			<main className='App-main' id='App-main-component'>

			<div className='Main-text'>
				<ul>
					<li>Ανώτατη τιμή δια νόμου για είδη πρώτης ανάγκης όπως ψωμί γάλα κλπ.</li>
					<li>Κτίσιμο εργατικών κατοικιών και φοιτητικών εστιών σε κρατική γη τα οποία θα νοικιάζονται σε ευάλωτες ομάδες
						με ενα μηνιαίο χαμηλό ενοίκιο γύρω στα 200 ευρό ώστε σε βάθος χρόνου να γίνει απόσβεση του κόστους.
					</li>
					<li>Δια νόμου ανώτατη τιμή σε όλα τα αναγκαία αγαθά.</li>
					<li>Ενημέρωση καταγγελιών ως αναφορά την κατανομή δικαιοσύνης προς τους πολίτες από μέρους του κράτους.</li>
					<li>Άμεση διόρθωση της δυσαναλογίας μεταξύ κράτους και τραπεζών σχετικά με το βιοτικό επίπεδο.</li>
					<li>αναβάθμιση συντάξεων αναλόγως κόστους ζωής.</li>
					<li>εφαρμογή ευρωπαϊκών οδηγιών σε όλες τις κατηγορίες.</li>
				</ul>
			</div>

			<div className='Main-image' id='akribeia'>
				
			</div>
				
			</main>
			<footer className="App-footer">
            <p>&copy; 2024 Erex8ewn NEMESIS</p>
          </footer>
		</div>
	)
}